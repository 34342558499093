// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Search from "../../blocks/search/src/Search";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Cfapitoparsetheresume from "../../blocks/cfapitoparsetheresume/src/Cfapitoparsetheresume";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Cfconnectinboxwithusersemail from "../../blocks/cfconnectinboxwithusersemail/src/Cfconnectinboxwithusersemail";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfautomaticapplicationtojobs from "../../blocks/cfautomaticapplicationtojobs/src/Cfautomaticapplicationtojobs";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Cfformjobmatchingengine from "../../blocks/cfformjobmatchingengine/src/Cfformjobmatchingengine";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfapisforjobsfromindeedlinkedinetc from "../../blocks/cfapisforjobsfromindeedlinkedinetc/src/Cfapisforjobsfromindeedlinkedinetc";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Twiliointegration from "../../blocks/twiliointegration/src/Twiliointegration";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Settings from "../../blocks/settings/src/Settings";
import Payments from "../../blocks/payments/src/Payments";
import Subscriptionbilling from "../../blocks/subscriptionbilling/src/Subscriptionbilling";




const routeMap = {
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
Twiliointegration:{
 component:Twiliointegration,
path:"/Twiliointegration"},
Signuplogin:{
 component:Signuplogin,
path:"/Signuplogin"},
Termsandconditions:{
 component:Termsandconditions,
path:"/Termsandconditions"},
Settings:{
 component:Settings,
path:"/Settings"},
Payments:{
 component:Payments,
path:"/Payments"},
Subscriptionbilling:{
 component:Subscriptionbilling,
path:"/Subscriptionbilling"},

StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Search:{
 component:Search,
path:"/Search"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Cfapitoparsetheresume:{
 component:Cfapitoparsetheresume,
path:"/Cfapitoparsetheresume"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
Cfconnectinboxwithusersemail:{
 component:Cfconnectinboxwithusersemail,
path:"/Cfconnectinboxwithusersemail"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfautomaticapplicationtojobs:{
 component:Cfautomaticapplicationtojobs,
path:"/Cfautomaticapplicationtojobs"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Cfformjobmatchingengine:{
 component:Cfformjobmatchingengine,
path:"/Cfformjobmatchingengine"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfapisforjobsfromindeedlinkedinetc:{
 component:Cfapisforjobsfromindeedlinkedinetc,
path:"/Cfapisforjobsfromindeedlinkedinetc"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
component:MobileAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;